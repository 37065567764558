/* You can add global styles to this file, and also import other style files */
html {
    position: relative;
    min-height: 100%;
}

body {

}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 700px;
    background-color: #f5f5f5;
}

@media (min-width: 375px) {
    body {
    }
    .footer {
        height: 615px;
    }
}

@media (min-width: 768px) {
    body {
    }
    .footer {
        height: 400px;
    }
}

@media (min-width: 1024px) {
    body {
    }
    .footer {
        height: 325px;
    }
}

@media (min-width: 1440px) {
    body {
    }
    .footer {
        height: 270px;
    }
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
}

.svg-inline--fa.fa-w-14 {
    width: .875em;
}

.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

::-webkit-scrollbar {
    -webkit-appearance: auto;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.page-section {
    padding: 1rem 0;
}



/* countdown block end */


.lineBreak {
    display: block;
    width: 30%;
    border-bottom: 4px solid #E91B18;
    margin: 20px auto;
}


#disclaimer {
    margin-top: 5%;
}

.disclaimer p {
    width: 90%;
    font-size: .7rem;
    margin: 0 auto;
}

.hiddenLink {
    position: absolute;
    display: block;
    width: 10rem;
    height: 3rem;
    z-index: 1;
    top: 67%;
    left: 10%;
}

.Texas .tx {
    display: inline-block;
}

.Texas .all {
    display: none;
}

.All .tx {
    display: none;
}

.All .all {
    display: inline-block;
}

.modalBG {
    position: absolute;
    background-color: rgba(000, 000, 000, .7);
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9999;
}

.customModel {
    position: absolute;
    z-index: 9999;
    background: #ffffff;
    top: 0;
    left: 0;
    padding: 1rem 0rem;
    width: 100%;
}

@media (min-width: 768px) {
    .customModel {
        transform: translate(-50%, -50%);
        top: 20%;
        left: 50%;
        padding: 1rem 0rem;
        width: 75%;
    }
}

.closeBtn.iconLink {
    float: right;
    margin-top: -5rem;
    margin-right: 1rem;
}

.closeBtn.iconLink img {
    width: 1rem;
    margin: 0;
}

.explaination span {
    color: #122478;
    font-weight: 500;   
}
