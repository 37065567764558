@font-face {
    font-family: 'UHC Sans';
    src: url('../fonts/UHCSans-SemiBold.woff2') format('woff2'), url('../fonts/UHCSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UHC Sans';
    src: url('../fonts/UHCSans-Bold.woff2') format('woff2'), url('../fonts/UHCSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UHC Sans';
    src: url('../fonts/UHCSans-Regular.woff2') format('woff2'), url('../fonts/UHCSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UHC Sans';
    src: url('../fonts/UHCSans-Black.woff2') format('woff2'), url('../fonts/UHCSans-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UHC Sans';
    src: url('../fonts/UHCSans-Medium.woff2') format('woff2'), url('../fonts/UHCSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UHC Serif Headline';
    src: url('../fonts/UHCSerifHeadline-Bold.woff2') format('woff2'), url('../fonts/UHCSerifHeadline-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Regular.woff2') format('woff2'), url('../fonts/MyriadPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'uhc_sansblack_italic';
    src: url('../fonts/uhcsans-blackitalic-webfont.woff2') format('woff2'), url('../fonts/uhcsans-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'uhc_sansmedium_italic';
    src: url('../fonts/uhcsans-mediumitalic-webfont.woff2') format('woff2'), url('../fonts/uhcsans-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'enterprise_sansbold';
    src: url('../fonts/enterprisesans-bold-webfont.woff2') format('woff2'),
         url('../fonts/enterprisesans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'enterprise_sansregular';
    src: url('../fonts/enterprisesans-regular-webfont.woff2') format('woff2'),
         url('../fonts/enterprisesans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}